import React, { ChangeEvent, Fragment, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Field, HStack } from '@rtkwlf/fenrir-react';
import { ThreeDots } from 'react-loader-spinner';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DatePicker } from '../../Reusables/DatePicker';
import { iRisk, RiskType } from '../../Global/riskReducer';
import { BLACK, BLUE } from '../../Constants/Styles';
import {
  READONLY_STATUS,
  RISK_SELECTABLE_STATES,
  RISK_STATES,
} from '../../Constants/Risks';
import { iState } from '../../configureStore';
import { iUser } from '../../Global/userReducer';
import { capitalize } from '../../utils/formatters';
import { iCase, iRiskAssignment } from '../../Global/caseReducer';
import {
  deleteAssignmentThunk,
  deleteRiskCaseThunk,
  updateAssignmentThunk,
  updateRiskCaseThunk,
} from '../../Global/caseActions';
import { updateRiskThunk } from '../../Global/riskActions';
import { getTimeMetrics } from '../../utils/riskUtils';
import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';
import { AcceptRisk } from '../../Modals/Modals/AcceptRisk';
import { Comments } from '../../Modals/Modals/Comments';
import { RemediationDetails } from './remediation-details/remediation-details';
import { useRiskDetails } from '../../Pages/Risks/RiskDetails/useRiskDetails';

type RiskAttributes = { [key: string]: any } | undefined;

type StateProps = {
  cases: Array<iCase>;
  riskData: iRisk;
  assignment: iRiskAssignment | undefined;
  attributes: RiskAttributes;
  users: Array<iUser>;
  riskType: RiskType;
};

type DispatchProps = {
  updateAssignment(
    riskId: string,
    update: Partial<iRiskAssignment>,
    riskType: string
  ): void;
  deleteAssignment(riskId: string, riskType: RiskType): void;

  updateCase(riskId: string, caseId: string, riskType: RiskType): void;
  deleteCase(riskId: string, caseId: string, riskType: RiskType): void;

  updateRisk(
    riskId: string,
    update: { [key: string]: string },
    riskType: RiskType,
    status: string
  ): void;
};

type OwnProps = {
  payload: {
    riskId: string;
  };
};

type iRiskPane = StateProps & DispatchProps & OwnProps;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledGroup = styled(StyledColumn)`
  margin-bottom: 15px;
`;

const StyledRow = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
`;

const StyledDescription = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${BLACK.primary};
`;

const StyledKey = styled.p`
  font-weight: bold;
  margin: 0px 10px 1px 0px;

  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: ${BLACK.primary};
`;

const StyledData = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 320;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #000204;
  word-break: break-word;
`;

const StyledHoverData = styled(StyledData)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledTitle = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;

  color: #23618e;
`;

const StyledLink = styled.a`
  color: #23618e;
  text-decoration: underline;
`;

const StyledHeader = styled.div`
  margin-bottom: 15px;
`;

const StyledSelect = styled.select`
  width: 250px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 30px;
`;

const NA = 'N/A';

const formatSource = (sourceText: string) => {
  switch (sourceText) {
    case 'sensor':
      return 'scanner';
    case 'reach':
      return 'external';
    case 'scout':
      return 'agent';
    default:
      return sourceText;
  }
};

const RiskCVEs = ({ attributes }: { attributes: RiskAttributes }) => {
  const [showMore, setShowMore] = useState(false);

  if (attributes && attributes?.cveList?.length > 0) {
    // Note splitting is required CVEs are coming in two different types
    // ['CVE-1999-0512, CVE-2002-1278, CVE-2003-0285']
    // ['CVE-2022-31736', 'CVE-2022-1919', 'CVE-2021-4129']
    // It is possible to have [''] so we need to filter it out

    const combinedCVEs = attributes.cveList
      .map((cve: string) => cve.split(', '))
      .filter((cve: Array<string>) => cve[0] !== '');

    if (combinedCVEs.length === 0) return <StyledData>{NA}</StyledData>;
    if (combinedCVEs.length === 1)
      return (
        <StyledLink
          key={combinedCVEs[0]}
          href={`https://nvd.nist.gov/vuln/detail/${combinedCVEs[0]}`}
          target='_blank'
        >
          {combinedCVEs[0]}
        </StyledLink>
      );

    const sortedCVEs = combinedCVEs
      .flat()
      .sort()
      .reverse()
      .map((cve: string) => {
        return (
          <StyledLink
            key={cve}
            href={`https://nvd.nist.gov/vuln/detail/${cve}`}
            target='_blank'
          >
            {cve}
          </StyledLink>
        );
      });
    return (
      <>
        {sortedCVEs[0]}
        {showMore && sortedCVEs.slice(1)}
        {showMore ? (
          <Button
            variant='link'
            onClick={() => setShowMore(false)}
            style={{ justifyContent: 'unset', marginTop: '5px' }}
          >
            See Less
          </Button>
        ) : (
          <Button
            variant='link'
            onClick={() => setShowMore(true)}
            style={{ justifyContent: 'unset', marginTop: '5px' }}
          >
            See More
          </Button>
        )}
      </>
    );
  }
  return <StyledData>{NA}</StyledData>;
};

export const RiskPane = ({
  assignment,
  updateCase,
  deleteCase,
  attributes,
  cases,
  deleteAssignment,
  riskData,
  riskType,
  updateAssignment,
  updateRisk,
  users,
}: iRiskPane) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const riskStates = Object.values(RISK_SELECTABLE_STATES);
  if (attributes?.status === 'Inactive' || riskData?.state === 'Mitigated') {
    riskStates.push('Mitigated');
  }
  if (riskData?.state === RISK_STATES.FAILED_VALIDATION) {
    // only display the 'Unsuccessful Validation' option for risks currently in that state
    riskStates.push(RISK_STATES.FAILED_VALIDATION);
  }

  const onCaseChange = React.useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value.length < 1 && attributes?.caseId) {
        deleteCase(riskData.id, attributes.caseId, riskType);
      } else {
        updateCase(riskData.id, e.target.value, riskType);
      }
    },
    [attributes, deleteCase, riskData, riskType, updateCase]
  );

  const timeMetrics = useMemo(() => {
    if (riskData) {
      return getTimeMetrics(
        riskData.attributes.firstIdentified,
        riskData.attributes.resolvedTime
      );
    }
  }, [riskData]);

  const getRiskPaneCase = (caseId: string | undefined) => {
    let planTitle = NA;

    if (caseId) {
      const filteredCase = cases.find((item: iCase) => item.id === caseId);
      planTitle = filteredCase ? capitalize(filteredCase.title) : NA;
    }

    return planTitle;
  };

  const isEditable = !READONLY_STATUS.includes(attributes?.status);
  const isAssigned = attributes?.assignment?.owner;

  const { data: riskDetailsData, isFetching: riskDetailsIsFetching } =
    useRiskDetails(
      riskData.customerID,
      riskData.source === 'agent',
      riskData.id,
      (data: iRisk) => {
        return data;
      }
    );

  const hasAnnotations = useMemo(() => {
    return riskDetailsData?.observations?.[0]?.jovalAnnotations;
  }, [riskDetailsData]);

  return (
    <Fragment>
      <StyledHeader>
        <StyledColumn>
          <StyledTitle>{riskData?.issueName}</StyledTitle>
          <StyledRow>
            <StyledKey>Last Updated</StyledKey>
            <StyledDescription>
              {riskData?.lastModifiedTime || NA}
            </StyledDescription>
          </StyledRow>
        </StyledColumn>
      </StyledHeader>
      <StyledColumn>
        <>
          <StyledGroup>
            <StyledKey>Resolution Date</StyledKey>
            <StyledData>{timeMetrics?.resolutionDate}</StyledData>
          </StyledGroup>
          <StyledGroup>
            <StyledKey>Age</StyledKey>
            <StyledData>{timeMetrics?.age}</StyledData>
          </StyledGroup>
          <StyledGroup>
            <StyledKey>Days to Resolution</StyledKey>
            <StyledData>{timeMetrics?.daysToResolution}</StyledData>
          </StyledGroup>
        </>
        <StyledGroup>
          <StyledKey>Action</StyledKey>
          <StyledData>{riskData?.issueAction || NA}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Risk Score</StyledKey>
          <StyledData>{riskData?.issueLevel || NA}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Issue Description</StyledKey>
          <StyledData>{riskData?.issueDescription || NA}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Additional Details</StyledKey>
          <StyledData>
            {riskData?.observations ? (
              riskData.source === 'agent' ? (
                riskDetailsIsFetching ? (
                  <HStack xAlign='center' yAlign='center' width='full'>
                    <ThreeDots color={BLUE.tertiary} height={50} width={50} />
                  </HStack>
                ) : hasAnnotations ? (
                  <StyledLink href={`/risk-details?riskid=${riskData?.id}`}>
                    See additional details
                  </StyledLink>
                ) : (
                  NA
                )
              ) : (
                <StyledLink href={`/risk-details?riskid=${riskData?.id}`}>
                  See additional details
                </StyledLink>
              )
            ) : (
              NA
            )}
          </StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Remediation</StyledKey>
          {riskData.source === 'agent' ? (
            <RemediationDetails
              customerId={riskData.customerID}
              riskId={riskData.id}
            />
          ) : riskData?.issueRecommendations &&
            riskData?.issueRecommendations?.length > 0 ? (
            riskData?.issueRecommendations?.map((recomendation: string) => (
              <StyledData key={recomendation}>{recomendation}</StyledData>
            ))
          ) : (
            <StyledData>{NA}</StyledData>
          )}
        </StyledGroup>

        <StyledGroup>
          <StyledKey>First Detected</StyledKey>
          <StyledData>{riskData?.attributes?.firstIdentified || NA}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Most Recent Detection</StyledKey>
          <StyledData>{riskData?.createTime || NA}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Status</StyledKey>
          <StyledData>{attributes?.status || NA}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey
            data-testid={'state' + (isEditable ? '-editable' : '-read-only')}
          >
            State
          </StyledKey>
          {!isEditable ? (
            riskData?.state
          ) : (
            <StyledSelect
              data-testid='state-select'
              id='risk-pane-state'
              value={riskData?.state || ''}
              onChange={(e: any) => {
                if (e.target.value === 'Accepted') {
                  setShowAcceptModal(true);
                } else {
                  return updateRisk(
                    riskData.id,
                    {
                      field: 'state',
                      value: e.target.value,
                    },
                    riskType,
                    riskData.attributes.status
                  );
                }
              }}
            >
              {[...Object.values(riskStates)].map(
                (state: string, index: number) => (
                  <option key={index} value={capitalize(state)}>
                    {capitalize(state)}
                  </option>
                )
              )}
            </StyledSelect>
          )}
        </StyledGroup>

        <StyledGroup>
          <StyledKey
            data-testid={
              'risk-owner' + (isEditable ? '-editable' : '-read-only')
            }
          >
            Assigned To
          </StyledKey>
          {!isEditable ? (
            attributes?.assignment?.owner || NA
          ) : (
            <StyledSelect
              data-testid='risk-owner-select'
              id='risk-pane-assignment'
              value={attributes?.assignment?.owner || ''}
              onChange={(e: any) => {
                if (e.target.value.length < 1)
                  return deleteAssignment(riskData.id, riskType);
                return updateAssignment(
                  riskData.id,
                  { owner: e.target.value },
                  riskType
                );
              }}
            >
              {[{ email: '' } as iUser, ...users].map(
                (user: iUser, index: number) => (
                  <option key={index} value={user.email}>
                    {user.email}
                  </option>
                )
              )}
            </StyledSelect>
          )}
        </StyledGroup>

        {attributes?.caseId && (
          <StyledGroup>
            <StyledKey
              data-testid={
                'start-date' + (isEditable ? '-editable' : '-read-only')
              }
            >
              Start Date
            </StyledKey>
            {!isEditable ? (
              <StyledData>{assignment?.startDate || NA}</StyledData>
            ) : (
              <DatePicker
                id='risk-pane-start-date'
                selected={
                  assignment?.startDate
                    ? new Date(assignment?.startDate)
                    : undefined
                }
                onChange={(event: any) =>
                  updateAssignment(
                    riskData.id,
                    {
                      startDate: moment.utc(event).format(),
                    },
                    riskType
                  )
                }
              />
            )}
          </StyledGroup>
        )}

        <StyledGroup>
          <Field.Root appearance={'warning'}>
            <StyledKey
              data-testid={`due-date${isEditable ? '-editable' : '-read-only'}`}
            >
              Due Date
            </StyledKey>
            {!isEditable || !isAssigned ? (
              attributes?.assignment?.dueDate || NA
            ) : (
              <DatePicker
                id='risk-pane-due-date'
                selected={
                  attributes?.assignment?.dueDate
                    ? new Date(attributes?.assignment?.dueDate)
                    : undefined
                }
                onChange={(event: any) =>
                  updateAssignment(
                    riskData.id,
                    {
                      dueDate: moment.utc(event).format(),
                    },
                    riskType
                  )
                }
              />
            )}
            {!isAssigned && (
              <Field.Message data-testid={'due-date-warning'}>
                A due date can not be chosen until an assignee is selected.
              </Field.Message>
            )}
          </Field.Root>
        </StyledGroup>

        <StyledGroup>
          <StyledKey
            data-testid={'caseId' + (isEditable ? '-editable' : '-read-only')}
          >
            Plan
          </StyledKey>
          {!isEditable ? (
            getRiskPaneCase(attributes?.caseId)
          ) : (
            <StyledSelect
              data-testid='caseId-select'
              id='risk-pane-case'
              value={attributes?.caseId || ''}
              onChange={onCaseChange}
            >
              {['', ...cases].map((state: any, index: number) => (
                <option key={index} value={state.id}>
                  {capitalize(state.title)}
                </option>
              ))}
            </StyledSelect>
          )}
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Host</StyledKey>
          <StyledData>
            {attributes?.hostDisplayName || riskData?.host || NA}
          </StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Source</StyledKey>
          <StyledData>
            {riskData?.source ? formatSource(riskData?.source) : NA}
          </StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Issue Category</StyledKey>
          <StyledData>{riskData?.issueCategory || NA}</StyledData>
        </StyledGroup>

        <StyledGroup data-testid='CVEs-details-link'>
          <StyledKey>CVEs</StyledKey>
          <RiskCVEs attributes={attributes} />
        </StyledGroup>

        <StyledGroup>
          <StyledKey>References</StyledKey>
          {riskData?.issueReferences &&
          riskData?.issueReferences?.length > 0 ? (
            riskData?.issueReferences?.map((reference: string) => (
              <StyledData key={reference}>{reference}</StyledData>
            ))
          ) : (
            <StyledData>{NA}</StyledData>
          )}
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Last Updated By</StyledKey>
          <StyledData>{riskData?.lastModifiedUser || NA}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Comments</StyledKey>
          <StyledData>
            <i
              data-testid='comments'
              className={'fa fa-comments'}
              onClick={() => setShowCommentsModal(true)}
            />
          </StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Asset ID</StyledKey>
          <StyledData>{riskData?.assetID || 'N/A'}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Scanner ID</StyledKey>
          <StyledData>{attributes?.scannerID || 'N/A'}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Deployment ID</StyledKey>
          <StyledData>
            {riskData?.source === 'sensor' ? riskData?.deploymentID : 'N/A'}
          </StyledData>
        </StyledGroup>

        {riskData?.notes && riskData?.state === 'Accepted' && (
          <StyledGroup>
            <StyledKey>Reason for Accepting</StyledKey>
            <StyledHoverData onClick={() => setShowAcceptModal(true)}>
              {riskData.notes}
            </StyledHoverData>
          </StyledGroup>
        )}

        {riskData?.resource && (
          <StyledGroup>
            <StyledKey>Resource</StyledKey>
            <StyledData>{riskData?.resource}</StyledData>
          </StyledGroup>
        )}

        <StyledGroup>
          <StyledKey>Host Annotations</StyledKey>
          <StyledData>{riskData?.hostAnnotations || NA}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Status Reason</StyledKey>
          <StyledData>{attributes?.statusReason || NA}</StyledData>
        </StyledGroup>

        <StyledGroup>
          <StyledKey>Issue Impact</StyledKey>
          <StyledData>{riskData?.issueImpact || NA}</StyledData>
        </StyledGroup>
      </StyledColumn>

      {showAcceptModal ? (
        <ModalBox onCloseModal={() => setShowAcceptModal(false)}>
          <AcceptRisk
            selectedRisks={[riskData]}
            closeModal={() => setShowAcceptModal(false)}
          />
        </ModalBox>
      ) : null}

      {showCommentsModal ? (
        <ModalBox
          onCloseModal={() => setShowCommentsModal(false)}
          position={ModalPositionType.top}
          width={'800px'}
        >
          <Comments risk={riskData} riskType={riskType} />
        </ModalBox>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (
  { cases, risk, user }: iState,
  { payload }: OwnProps
): StateProps => {
  let riskType: RiskType = RiskType.risks;
  let riskData = risk.risks.get(payload.riskId);

  if (!riskData) {
    riskData = risk.mitigatedRisks.get(payload.riskId);
    riskType = RiskType.mitigatedRisks;
  }
  if (!riskData) {
    riskData = risk.assignedRisks.get(payload.riskId);
    riskType = RiskType.assignedRisks;
  }

  return {
    cases: Array.from(cases.cases.values()),
    riskData: riskData!,
    assignment: riskData?.attributes?.assignment,
    attributes: riskData?.attributes,
    users: user.users,
    riskType,
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    updateAssignment: (
      riskId: string,
      update: Partial<iRiskAssignment>,
      riskType: RiskType
    ) => dispatch(updateAssignmentThunk(riskId, update, riskType)),
    deleteAssignment: (riskId: string, riskType: RiskType) =>
      dispatch(deleteAssignmentThunk(riskId, riskType)),
    updateCase: (riskId: string, caseId: string, type: RiskType) => {
      dispatch(updateRiskCaseThunk(riskId, caseId, type));
    },
    deleteCase: (riskId: string, caseId: string, riskType: RiskType) => {
      dispatch(deleteRiskCaseThunk(riskId, caseId, riskType));
    },
    updateRisk: (
      riskId: string,
      update: { [key: string]: string },
      type: string,
      status: string
    ) => dispatch(updateRiskThunk(riskId, update, type, status)),
  };
};

export default connect<StateProps, DispatchProps, OwnProps, iState>(
  mapStateToProps,
  mapDispatchToProps
)(RiskPane);
